import { render, staticRenderFns } from "./ModuleDetailsView.vue?vue&type=template&id=651e4049&scoped=true&"
import script from "./ModuleDetailsView.vue?vue&type=script&lang=js&"
export * from "./ModuleDetailsView.vue?vue&type=script&lang=js&"
import style0 from "./ModuleDetailsView.vue?vue&type=style&index=0&id=651e4049&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "651e4049",
  null
  
)

export default component.exports